<template>
  <view-spinner :show="loading" fullscreen/>
  <q-layout v-if="!loading" view="hhr lpR fff">
    <HeaderNavigation ref="headerNavigationRef" @hamburger-click="toggleNavbar" />

    <GlobalNavbar
      ref="navbar"
      v-model="navbarState"
      :show-if-above="navbarState"
      side="right"
      :persistent="$q.screen.gt.sm"
    />

    <q-page-container style="padding-bottom: 0; padding-top: 72px">
      <router-view />
    </q-page-container>

    <FooterBar />
  </q-layout>
  
  <q-dialog v-model="countdownModal.show" persistent transition-show="scale" transition-hide="scale">
    <q-card style="width: 500px">
      <q-card-section>
        <div class="text-h6">You are about to log-out</div>
      </q-card-section>
      
      <q-card-section class="q-pt-none">
        <span v-html="countdownModal.message"/>
      </q-card-section>
      
      <q-separator/>
      
      <q-card-actions class="q-pa-md" align="center">
        <q-btn label="Continue" color="info" @click="hideLogoutPrompt"/>
        <q-btn label="Log-out" color="secondary" @click="logout"/>
      </q-card-actions>
    </q-card>
  </q-dialog>
  
  <cookie-consent/>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, provide, ref, watch } from 'vue';
import { QDrawer, useQuasar } from 'quasar';
import GlobalNavbar from 'src/components/GlobalNavbar.vue';
import FooterBar from 'components/FooterBar.vue';
import HeaderNavigation from 'components/HeaderNavigation.vue';
import AuthService from 'src/services/AuthService';
import ViewSpinner from 'components/ViewSpinner.vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from 'stores/auth';
import { AxiosError } from 'axios';
import { handleError } from 'src/composables/ErrorHandler';
import { User } from 'oidc-client-ts';
import { useServicesStore } from 'stores/services';
import CookieConsent from 'components/CookieConsent.vue';
import { useSSRContext } from 'vue';

export default defineComponent({
  name: 'RootLayout',

  components: {
    CookieConsent,
    ViewSpinner,
    HeaderNavigation,
    GlobalNavbar,
    FooterBar,
  },

  setup() {
    const $q = useQuasar();
    const $store = useAuthStore();
    const route = useRoute();
    const router = useRouter();
    
    const headerNavigationRef = ref();

    const username = computed(() => {
      return $store.user.username;
    });
    const navbar = ref<QDrawer>();
    const navbarState = ref(!!username.value);
    let loading = ref(false);
    
    const ssrContext = process.env.SERVER ? useSSRContext() : null;
    const appRoot = process.env.SERVER
      ? ssrContext?.req.headers.host
      : window.location.origin;

    const authService = new AuthService(appRoot);
    
    const countdownModal = ref({
      show: false,
      message: '',
      seconds: 300, // 5 minutes in seconds
    })
    const countdownInterval = ref();
    const inactivityTimer = ref();
    const inactivityThreshold = 15 * 60 * 1000; // 15 minutes in milliseconds

    watch(username, () => {
      if (username.value) {
        document.addEventListener('mousemove', resetInactivityTimer);
        document.addEventListener('keydown', resetInactivityTimer);
      }
    });
    
    function toggleNavbar() {
      navbarState.value = !navbarState.value;
    }
    
    function resetInactivityTimer() {
      clearTimeout(inactivityTimer.value);
      
      inactivityTimer.value = setTimeout(showLogoutPrompt, inactivityThreshold);
    }
    
    function showLogoutPrompt() {
      countdownModal.value.show = true;
      
      updateCountdown();
      
      countdownInterval.value = setInterval(updateCountdown, 1000);
    }
    
    function hideLogoutPrompt() {
      countdownModal.value.show = false;
      countdownModal.value.seconds = 300;
      clearInterval(countdownInterval.value);
      clearTimeout(inactivityTimer.value);
    }
    
    function logout() {
      navbarState.value = false;
      $store.oidcLogout();
      hideLogoutPrompt();
      
      document.removeEventListener('mousemove', resetInactivityTimer);
      document.removeEventListener('keydown', resetInactivityTimer);
    }
    
    function updateCountdown() {
      const minutes = Math.floor(countdownModal.value.seconds / 60);
      const remainingSeconds = countdownModal.value.seconds % 60;
      countdownModal.value.message = `Due to inactivity you will be logged out in <b>${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}</b>. Do you want to continue session?`
      
      if (countdownModal.value.seconds === 0) {
        logout();
      } else {
        countdownModal.value.seconds--;
      }
    }
    
    provide('navbarState', navbarState);
  
    onMounted(async () => {
      const returnFrom = route.query.return_from;
      if (returnFrom) {
        headerNavigationRef.value.onLoginClick();
        router.replace({ query: {} });
      }
      
      loading.value = true;
      
      const oidcUser = authService.getOidcUserFromLocalStorage();
      if (oidcUser) {
        authService
            .getUser()
            .then((user: User | null) => {
              if (!user) {
                authService.storeUser(oidcUser);
              }
            })
            .catch((e: AxiosError<string>) => {
              handleError(e, $q)
            });
        await $store
            .getUserDetails()
            .then(() => {
              if (route.query?.redirect) {
                void router
                    .push(route.query?.redirect?.toString())
                    .catch(() => {
                      void router.push('/');
                    });
              }
            })
            .catch((e: AxiosError<string>) => {
              console.error(e);
            });
      }
  
      loading.value = false;
    })
    
    return {
      navbar,
      navbarState,
      toggleNavbar,
      loading,
      headerNavigationRef,
      countdownModal,
      hideLogoutPrompt,
      logout,
    };
  },
  
  preFetch() {
    const $store = useServicesStore();
    
    return $store.loadAppointmentCategories();
  },
});
</script>

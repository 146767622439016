<template>
  <cookie-accept-decline position="bottom" disable-decline type="bar" element-id="clinic-cookie-consent">
    
    <template v-slot:message>
      <div class="cookie__bar__text">
        Cookies are an industry standard and most major web sites use them. To improve your experience on our site, we
        use 'cookies' to remember log-in details and provide secure log-in, collect statistics to optimize site
        functionality, and deliver content tailored to your interests.
        <router-link :to="{name: 'privacy-notice'}" class="cookie__bar__link">Privacy Statement</router-link>
      </div>
    </template>
  
  </cookie-accept-decline>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CookieAcceptDecline from 'vue-cookie-accept-decline';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';

export default defineComponent({
  name: 'CookieConsent',
  components: { CookieAcceptDecline },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.cookie__bar {
  background: #FFFFFF;
}

.cookie__bar__text {
  color: #696969;
  font-size: 16px;
}

:deep(.cookie__bar__content) {
  max-height: unset;
  padding: 0 10px;
}

.cookie__bar__link {
  color: $gompels-green
}

:deep(.cookie__bar__buttons__button--accept) {
  background: $homeward-purple ;
}
</style>
<template>
  <q-header bordered class="bg-white text-homeward-purple print-hide" style="position: fixed">
    <q-toolbar class="q-pa-none">
      <img
          src="../assets/homeward-pharmacy-logo.webp"
          class="q-pa-xs q-px-md cursor-pointer gt-xs"
          @click="$router.push('/')"
          loading="eager"
      />
      <q-space/>
      <q-tabs class="q-pa-none header-tabs" shrink stretch outside-arrows>
        <q-route-tab label="About" icon="fad fa-scroll-old" to="/about"/>
        <q-route-tab label="Resources" icon="fad fa-book" to="/resources"/>
        
        <q-btn-dropdown
            class="q-pr-none"
            auto-close stretch flat
            :class="{ navbarOpen: navigationState('dispensing-service') }">
          <template v-slot:label>
            <div>
              <div class="row justify-center items-center no-wrap">
                <q-icon name="fad fa-prescription-bottle-alt"/>
              </div>
              <div class="row items-center no-wrap">
                Dispensing Service
              </div>
            </div>
          </template>
          
          <q-item
              class="dropdown-item" clickable to="/dispensing-service/nutricia-homeward-service">
            <q-item-section>Nutricia Homeward Service</q-item-section>
          </q-item>
          
          <q-item class="dropdown-item" clickable to="/dispensing-service/homeward-pharmaceutical-service">
            <q-item-section>Homeward Pharmaceutical Service</q-item-section>
          </q-item>
        </q-btn-dropdown>
        
        <q-btn-dropdown
            class="q-pr-none"
            auto-close stretch flat
            :class="{ navbarOpen: navigationState('health-advice') }">
          <template v-slot:label>
            <div>
              <div class="row justify-center items-center no-wrap">
                <q-icon name="fad fa-heartbeat"/>
              </div>
              <div class="row items-center no-wrap">
                Health Advice
              </div>
            </div>
          </template>
          
          <q-item
              class="dropdown-item" clickable to="/health-advice/signposting">
            <q-item-section>Signposting</q-item-section>
          </q-item>
          
          <q-item class="dropdown-item" clickable to="/health-advice/public-health-england">
            <q-item-section>Public Health England</q-item-section>
          </q-item>
          
          <q-item class="dropdown-item" clickable to="/health-advice/nhs-health-advice">
            <q-item-section>NHS Health Advice</q-item-section>
          </q-item>
        </q-btn-dropdown>
        
        <q-btn-dropdown
            class="q-pr-none"
            auto-close stretch flat
            :class="{ navbarOpen: navigationState('contact') }">
          <template v-slot:label>
            <div>
              <div class="row justify-center items-center no-wrap">
                <q-icon name="fad fa-phone"/>
              </div>
              <div class="row items-center no-wrap">
                Contact
              </div>
            </div>
          </template>
          
          <q-item
              class="dropdown-item" clickable to="/contact">
            <q-item-section>Contact Us</q-item-section>
          </q-item>
          
          <q-item class="dropdown-item" clickable to="/contact/returns">
            <q-item-section>Returns</q-item-section>
          </q-item>
          
          <q-item class="dropdown-item" clickable to="/contact/comments-and-complaints">
            <q-item-section>Comments And Complaints</q-item-section>
          </q-item>
        </q-btn-dropdown>
        
        <!--
        <q-btn-dropdown
          class="q-pr-none"
          auto-close stretch flat
          :class="{ navbarOpen: navigationState('services') }">
          <template v-slot:label>
            <div>
              <div class="row justify-end items-center no-wrap">
                <q-icon name="fad fa-user-md-chat"/>
              </div>
              <div class="row items-center no-wrap">
                Services
              </div>
            </div>
          </template>
          
          <q-item
              v-for="category in appointmentCategories" :key="category.id"
              class="dropdown-item"
              clickable
              :to="{name: 'service-category', params: {category_id: category.id}}">
            <q-item-section>{{ category.name }}</q-item-section>
          </q-item>
        </q-btn-dropdown>
        -->
        
        <q-btn-dropdown
            v-show="permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_AVAILABILITY) && permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_APPOINTMENT)"
            auto-close
            stretch
            flat
            class="q-pr-none"
            :class="{ navbarOpen: navigationState('clinic') }">
          <template v-slot:label>
            <div>
              <div class="row justify-end items-center no-wrap">
                <q-icon name="fad fa-house-chimney-medical"/>
              </div>
              <div class="row items-center no-wrap">
                Clinic
              </div>
            </div>
          </template>
          
          <q-list>
            <h6 class="q-ma-none dropdown-header" v-if="user.staff">
              Your Times
            </h6>
            <q-item
                v-if="user.staff" class="dropdown-item" clickable
                :to="{
                  name: 'your-schedule-view',
                  params: {
                      action: 'schedule',
                      type: 'staff',
                      id: user.staff.id,
                      day: getCurrentDate.day,
                      month: getCurrentDate.month,
                      year: getCurrentDate.year ,
                    }
                }">
              <q-item-section>Your Schedule</q-item-section>
            </q-item>
            <q-item
                v-if="user.staff" class="dropdown-item" clickable
                :to="{
                  name: 'your-availability-view',
                  params: {
                      action: 'availability',
                      type: 'staff',
                      id: user.staff.id,
                      day: getCurrentDate.day,
                      month: getCurrentDate.month,
                      year: getCurrentDate.year ,
                    }
                }">
              <q-item-section>Your Availability</q-item-section>
            </q-item>
            
            <h6 class="q-ma-none dropdown-header"
                v-if="permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_STAFF) ||
                permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_ROOM)">
              Schedules
            </h6>
            <q-item
                v-if="permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_STAFF)"
                class="dropdown-item"
                clickable
                to="/clinic/schedule/staff">
              <q-item-section>Clinician Schedules</q-item-section>
            </q-item>
            <q-item
                v-if="permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_ROOM)"
                class="dropdown-item"
                clickable
                to="/clinic/schedule/room">
              <q-item-section>Room Schedules</q-item-section>
            </q-item>
            
            <h6 v-if="permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_AVAILABILITY)"
                class="q-ma-none dropdown-header">
              Availabilities
            </h6>
            <q-item
                v-if="permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_AVAILABILITY)"
                class="dropdown-item"
                clickable
                to="/clinic/availability">
              <q-item-section>Clinician Availabilities</q-item-section>
            </q-item>
            
            <h6 class="q-ma-none dropdown-header">Reporting</h6>
            <q-item class="dropdown-item" clickable to="/clinic/temporary-reporting">
              <q-item-section>Temporary Reporting</q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        
        <q-btn-dropdown
            v-show="permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_AVAILABILITY) ||
            permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_APPOINTMENT) ||
            permissions.includes(USER_PERMISSIONS.AUTH_VIEW_GROUP) ||
            permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_USER) ||
            permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_STAFF) ||
            permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_SITE) ||
            permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_ROOM) ||
            permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_GPSURGERY) ||
            permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_COMPETENCY) ||
            permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_APPOINTMENT) ||
            permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_APPOINTMENTTYPE) ||
            permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_APPOINTMENTOUTCOME)"
            auto-close
            stretch
            flat
            class="q-pr-none"
            :class="{ navbarOpen: navigationState('admin') }">
          <template v-slot:label>
            <div>
              <div class="row justify-end items-center no-wrap">
                <q-icon name="fad fa-user-shield"/>
              </div>
              <div class="row items-center no-wrap">
                Admin
              </div>
            </div>
          </template>
          
          <q-list>
            <q-item
                v-if="permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_APPOINTMENT)"
                class="dropdown-item"
                clickable
                to="/admin/appointments">
              <q-item-section>Appointments</q-item-section>
            </q-item>
            <q-item
                v-if="permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_APPOINTMENTTYPE)"
                class="dropdown-item"
                clickable
                to="/admin/appointment-types">
              <q-item-section>Appointment Types</q-item-section>
            </q-item>
            <q-item
                v-if="permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_APPOINTMENTTYPE)"
                class="dropdown-item"
                clickable
                to="/admin/appointment-categories">
              <q-item-section>Appointment Categories</q-item-section>
            </q-item>
            <q-item
                v-if="permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_APPOINTMENTOUTCOME)"
                class="dropdown-item"
                clickable
                to="/admin/appointment-outcomes">
              <q-item-section>Appointment Outcomes</q-item-section>
            </q-item>
            <q-item
                v-if="permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_COMPETENCY)"
                class="dropdown-item"
                clickable
                to="/admin/competencies">
              <q-item-section>Competencies</q-item-section>
            </q-item>
            <q-item
                v-if="permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_GPSURGERY)"
                class="dropdown-item"
                clickable
                to="/admin/surgeries">
              <q-item-section>GP Surgeries</q-item-section>
            </q-item>
            <q-item
                v-if="permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_ROOM)"
                class="dropdown-item"
                clickable
                to="/admin/rooms">
              <q-item-section>Rooms</q-item-section>
            </q-item>
            <q-item
                v-if="permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_SITE)"
                class="dropdown-item"
                clickable
                to="/admin/sites">
              <q-item-section>Sites</q-item-section>
            </q-item>
            <q-item
                v-if="permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_STAFF)"
                class="dropdown-item"
                clickable
                to="/admin/staff">
              <q-item-section>Clinician</q-item-section>
            </q-item>
            <q-item
                v-if="permissions.includes(USER_PERMISSIONS.EFORMS_VIEW_EFORM)"
                class="dropdown-item"
                clickable
                to="/admin/eforms">
              <q-item-section>eForms Templates</q-item-section>
            </q-item>
            <q-item
                v-if="permissions.includes(USER_PERMISSIONS.CLINIC_VIEW_USER)"
                class="dropdown-item"
                clickable
                to="/admin/users">
              <q-item-section>Users</q-item-section>
            </q-item>
            <q-item
                v-if="permissions.includes(USER_PERMISSIONS.AUTH_VIEW_GROUP)"
                class="dropdown-item"
                clickable
                to="/admin/groups">
              <q-item-section>Groups</q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        <q-route-tab
            v-show="!authenticated"
            label="Login / Register"
            icon="fad fa-sign-in"
            @click="onLoginClick"
        />
        <q-btn
            v-show="authenticated"
            flat
            stack
            class="q-tab relative-position self-stretch flex flex-center text-center q-focusable q-hoverable cursor-pointer q-tab__content q-anchor--skip non-selectable column no-border-radius q-px-sm header-icon-fix"
            :class="{ navbarOpen: navbarState }"
            icon="fad fa-user"
            label="My Profile"
            aria-label="My Profile"
            @click="$emit('hamburger-click')"
        />
      </q-tabs>
      <q-space class="lt-sm"/>
    </q-toolbar>
  </q-header>
</template>

<script lang="ts">
import { computed, defineComponent, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AuthService from 'src/services/AuthService';
import { useQuasar } from 'quasar';
import { useAuthStore } from 'stores/auth';
import { User } from 'oidc-client-ts';
import { AxiosError } from 'axios';
import { handleError } from 'src/composables/ErrorHandler';
import { USER_PERMISSIONS } from 'src/constants/Permissions';
import { useServicesStore } from 'stores/services';
import { useSSRContext } from 'vue';

export default defineComponent({
  name: 'HeaderNavigation',
  setup() {
    const $q = useQuasar();
    const $store = useAuthStore();
    const $servicesStore = useServicesStore();
    const router = useRouter();
    const route = useRoute();
    
    const authenticated = computed(() => {
      return $store.authenticated;
    });
    const permissions = computed(() => {
      return $store.user.permissions;
    });
    const user = computed(() => {
      return $store.user;
    });
    const appointmentCategories = computed(() => {
      return $servicesStore.categories;
    });
    const getCurrentDate = computed(() => {
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      
      return { day, month, year };
    });
    const navbarState = inject('navbarState');
    
    const ssrContext = process.env.SERVER ? useSSRContext() : null;
    const appRoot = process.env.SERVER
      ? ssrContext?.req.headers.host
      : window.location.origin;

    const authService = new AuthService(appRoot);
    
    const navigationState = computed(() => {
      return (routeName: string) => {
        const path = router.currentRoute.value.path;
        const splitPath = path.split('/');
        
        if (splitPath.length > 1) {
          return splitPath[1] === routeName;
        }
        return path.includes(routeName);
      };
    });
    
    function onLoginClick() {
      authService
          .signinPopup()
          .then(async (user: User) => {
            authService.setOidcUserInLocalStorage(user);
            $store.$patch((state) => {
              state.oidc_user.access_token = user.access_token;
              state.oidc_user.expires_at = user.expires_at;
              state.oidc_user.profile.sub = user.profile.sub;
            });
            await $store
                .getUserDetails()
                .then(() => {
                  if (route.query?.redirect) {
                    void router
                        .push(route.query?.redirect?.toString())
                        .catch(() => {
                          void router.push('/');
                        });
                  }
                })
                .catch((e: AxiosError<string>) => {
                  handleError(e, $q);
                });
          })
          .catch((e: AxiosError<string>) => {
            handleError(e, $q);
          });
    }
    
    return {
      authenticated,
      permissions,
      user,
      getCurrentDate,
      navbarState,
      navigationState,
      appointmentCategories,
      onLoginClick,
      USER_PERMISSIONS,
    };
  },
});
</script>

<style lang="scss" scoped>
.navbarOpen {
  background-color: transparent;
  border-bottom: 2px solid $homeward-purple;
}

.dropdown-header {
  color: $homeward-purple;
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: .875rem;
  white-space: nowrap;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
</style>

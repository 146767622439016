<template>
  <q-footer v-show="version === 'Development'"
            bordered
            class="bg-grey-1 text-black"
            style="height: 1.5rem; padding-top: 0.2rem">
    <q-toolbar
        style="line-height: 1; font-size: 0.8rem; min-height: 1rem; color: #777">
      <div class="cursor-pointer">
        <div v-if="user.username">
          {{ user.first_name }}
          {{ user.last_name }}
          ({{ user.username }})
        </div>
        <div v-else>Not logged in</div>
      </div>
      <div
          v-if="auth_token.token"
          class="cursor-pointer q-mx-sm ellipsis gt-sm">
        [{{ auth_token.token }}]
      </div>
      <div></div>
      <q-space/>
      <div class="q-pr-sm gt-sm">
        API Base:
        <i>
          <a :href="api_base" target="_blank">{{ api_base }}</a>
        </i>
      </div>
      <div>
        Version: <i>{{ version }}</i>
      </div>
    </q-toolbar>
  </q-footer>
</template>

<script lang="ts">
import { useAuthStore } from 'src/stores/auth';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'FooterBar',
  setup() {
    const $store = useAuthStore();
    
    const user = computed(() => {
      return $store.user;
    });
    
    const auth_token = computed(() => {
      return $store.auth_token;
    });
    return {
      api_base: process.env.API_BASE,
      version: process.env.VERSION,
      user,
      auth_token,
    };
  },
});
</script>

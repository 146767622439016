<template>
  <q-drawer :width="250" :breakpoint="800" class="print-hide">
    <q-scroll-area
      style="
        height: calc(100% - 150px);
        margin-top: 150px;
        border-right: 1px solid #ddd;
      ">
      <q-list padding class="text-grey-7">
        <q-item>
          <q-item-section class="text-center text-bold">Services</q-item-section>
        </q-item>

        <q-item clickable v-ripple to="/user/appointments">
          <q-item-section avatar>
            <q-icon name="fad fa-calendar-check" />
          </q-item-section>

          <q-item-section>Appointments</q-item-section>
        </q-item>

        <q-item>
          <q-item-section class="text-center text-bold">eForms</q-item-section>
        </q-item>

        <q-item clickable v-ripple to="/user/forms/in-progress">
          <q-item-section avatar>
            <q-icon name="fad fa-clipboard-list-check" />
          </q-item-section>

          <q-item-section>In-Progress</q-item-section>
        </q-item>

        <q-item clickable v-ripple to="/user/forms/completed">
          <q-item-section avatar>
            <q-icon name="fad fa-clipboard-check" />
          </q-item-section>

          <q-item-section>Completed</q-item-section>
        </q-item>

        <q-item>
          <q-item-section class="text-center text-bold">User</q-item-section>
        </q-item>

        <q-item clickable v-ripple to="/user/profile">
          <q-item-section avatar>
            <q-icon name="fad fa-id-card" />
          </q-item-section>

          <q-item-section>Profile</q-item-section>
        </q-item>

        <q-item clickable v-ripple @click="logout">
          <q-item-section avatar>
            <q-icon name="fad fa-sign-out" />
          </q-item-section>

          <q-item-section>Log-out</q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>

    <q-img class="absolute-top bg-homeward-purple" style="height: 150px">
      <div class="absolute-bottom bg-transparent">
        <q-avatar size="56px" class="q-mb-sm">
          <img src="https://cdn.quasar.dev/img/boy-avatar.png" />
        </q-avatar>
        <div class="text-weight-bold">
          {{ user.first_name }} {{ user.last_name }}
        </div>
      </div>
    </q-img>
  </q-drawer>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useAuthStore } from 'stores/auth';
import { useSSRContext } from 'vue';
import AuthService from 'src/services/AuthService';

export default defineComponent({
  name: 'GlobalNavbar',

  components: {},

  setup() {
    const $store = useAuthStore();

    const ssrContext = process.env.SERVER ? useSSRContext() : null;
    const appRoot = process.env.SERVER
      ? ssrContext?.req.headers.host
      : window.location.origin;

    const authService = new AuthService(appRoot);

    const user = computed(() => {
      return $store.user;
    });

    function logout() {
      authService.signoutRedirect();
    }

    return {
      user,
      logout,
    };
  },
});
</script>

<style lang="scss" scoped>
.q-item.q-router-link--active,
.q-item--active {
  background: $homeward-purple;
  color: #fff;
}

.q-item {
  color: $homeward-purple;
}

.q-item.q-router-link--active .q-icon,
.q-item--active .q-icon {
  color: #fff;
}
</style>
